<template>
  <div class="page-container">
    <section>
      <div class="content-band max-w1200">
        <div class="tabs">
          <div class="flex" role="tablist" aria-label="Tabs testing">
            <button
              v-for="(item, index) in buttonNames"
              :key="item.title"
              role="tab"
              aria-selected="true"
              v-on:keyup="keyupEventListener($event, index)"
              v-on:click="openTab"
            >
              {{ item.title }}
            </button>
          </div>
          <div class="tabpanel-container fade">
            <div
              class="container p-40"
              tabindex="0"
              role="tabpanel"
              id="nils-tab"
              aria-labelledby="nils"
            >
              <h3>London</h3>
              <p>London is the capital city of England.</p>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cumque
                iure veritatis consequatur ex beatae obcaecati asperiores
                doloremque repellat eos, hic sit neque quam quibusdam deserunt?
                Nihil est aliquid corporis, alias aspernatur tempore assumenda
                itaque ipsa. Optio placeat commodi rem error dolorum officiis
                saepe at reprehenderit, amet aperiam! Et, tempore voluptatibus.
              </p>
            </div>

            <div
              class="container p-40"
              tabindex="0"
              role="tabpanel"
              id="agnes-tab"
              aria-labelledby="agnes"
              hidden=""
            >
              <h3>Paris</h3>
              <p>Paris is the capital city of France.</p>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cumque
                iure veritatis consequatur ex beatae obcaecati asperiores
                doloremque repellat eos, hic sit neque quam quibusdam deserunt?
                Nihil est aliquid corporis, alias aspernatur tempore assumenda
                itaque ipsa. Optio placeat commodi rem error dolorum officiis
                saepe at reprehenderit, amet aperiam! Et, tempore voluptatibus.
              </p>
            </div>

            <div
              class="container p-40"
              tabindex="0"
              role="tabpanel"
              id="complexcomplex"
              aria-labelledby="complex"
              hidden=""
            >
              <h3>Tokyo</h3>
              <p>
                Fear of complicated buildings:
              </p>
              <p>
                Optio placeat commodi rem error dolorum officiis saepe at
                reprehenderit, amet aperiam! Et, tempore voluptatibus.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "TabComponentDefault",
  data: function() {
    return {
      tabs: [],
      pannels: [],
      buttonNames: [
        { title: "London", getTab: "nils-tab" },
        { title: "Paris", getTab: "agnes-tab" },
        { title: "Tokyo", getTab: "complexcomplex" },
      ],
      direction: {
        37: -1,
        39: 1,
      },
      keys: {
        left: 37,
        right: 39,
      },
    };
  },
  mounted() {
    this.tabs = document.querySelectorAll('[role="tab"]');
    this.panels = document.querySelectorAll('[role="tabpanel"]');
    this.tabs.forEach(function(value, index) {
      if (index != 0) {
        value.setAttribute("aria-selected", "false");
      }
    });
  },
  methods: {
    openTab: function(event) {
      this.deactivateAll();
      var outerText = "";
      if (event.target) {
        outerText = event.target.outerText;
      } else {
        outerText = event.outerText;
      }
      for (var k = 0; k < this.buttonNames.length; k++) {
        if (outerText === this.buttonNames[k].title) {
          var controls = this.buttonNames[k].getTab;
          for (var l = 0; l < this.tabs.length; l++) {
            if (outerText === this.tabs[l].outerText) {
              this.tabs[l].setAttribute("aria-selected", "true");
            }
          }
          // Remove hidden attribute from tab panel to make it visible
          document.getElementById(controls).removeAttribute("hidden");
        }
      }
    },
    deactivateAll: function() {
      for (var i = 0; i < this.panels.length; i++) {
        this.panels[i].setAttribute("hidden", "hidden");
      }
      for (var j = 0; j < this.tabs.length; j++) {
        this.tabs[j].setAttribute("tabindex", "-1");
        this.tabs[j].setAttribute("aria-selected", "false");
      }
    },
    keyupEventListener: function(event, index) {
      var pressed = event.keyCode;

      if (this.direction[pressed]) {
        if (index !== undefined) {
          if (this.tabs[index + this.direction[pressed]]) {
            this.tabs[index + this.direction[pressed]].focus();
            var goToTab = this.tabs[index + this.direction[pressed]];
            this.openTab(goToTab);
          } else if (pressed === this.keys.left) {
            this.focusLastTab();
          } else if (pressed === this.keys.right) {
            this.focusFirstTab();
          }
        }
      }
    },
    focusLastTab() {
      this.tabs[this.tabs.length - 1].focus();
      this.openTab(this.tabs[this.tabs.length - 1]);
    },
    focusFirstTab() {
      this.tabs[0].focus();
      this.openTab(this.tabs[0]);
    },
  },
};
</script>
