<template>
  <div class="page-container">
    <section>
      <div class="content-band max-w1200">
        <div class="tabs tabs-solid">
          <div class="flex tabs-red" role="tablist" aria-label="Tabs testing">
            <button
              v-for="(item, index) in buttonNames"
              :key="item.title"
              role="tabsolid"
              aria-selected="true"
              v-on:keyup="keyupEventListener($event, index)"
              v-on:click="openTab"
            >
              {{ item.title }}
            </button>
          </div>
          <div class="tabpanel-container fade">
            <div
              class="container p-40"
              tabindex="0"
              role="tabpanelsolid"
              id="france-tab"
              aria-labelledby="nils"
            >
              <h3>France</h3>
              <p>London is the capital city of England.</p>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cumque
                iure veritatis consequatur ex beatae obcaecati asperiores
                doloremque repellat eos, hic sit neque quam quibusdam deserunt?
                Nihil est aliquid corporis, alias aspernatur tempore assumenda
                itaque ipsa. Optio placeat commodi rem error dolorum officiis
                saepe at reprehenderit, amet aperiam! Et, tempore voluptatibus.
              </p>
            </div>

            <div
              class="container p-40"
              tabindex="0"
              role="tabpanelsolid"
              id="italy-tab"
              aria-labelledby="agnes"
              hidden=""
            >
              <h3>Italy</h3>
              <p>Paris is the capital city of France.</p>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cumque
                iure veritatis consequatur ex beatae obcaecati asperiores
                doloremque repellat eos, hic sit neque quam quibusdam deserunt?
                Nihil est aliquid corporis, alias aspernatur tempore assumenda
                itaque ipsa. Optio placeat commodi rem error dolorum officiis
                saepe at reprehenderit, amet aperiam! Et, tempore voluptatibus.
              </p>
            </div>

            <div
              class="container p-40"
              tabindex="0"
              role="tabpanelsolid"
              id="greece-tab"
              aria-labelledby="complex"
              hidden=""
            >
              <h3>Greece</h3>
              <p>
                Fear of complicated buildings:
              </p>
              <p>
                Optio placeat commodi rem error dolorum officiis saepe at
                reprehenderit, amet aperiam! Et, tempore voluptatibus.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
export default {
  name: "TabComponentSolid",
  data: function() {
    return {
      tabs: [],
      pannels: [],
      buttonNames: [
        { title: "France", getTab: "france-tab" },
        { title: "Italy", getTab: "italy-tab" },
        { title: "Greece", getTab: "greece-tab" },
      ],
      direction: {
        37: -1,
        39: 1,
      },
      keys: {
        left: 37,
        right: 39,
      },
    };
  },
  mounted() {
    this.tabs = document.querySelectorAll('[role="tabsolid"]');
    this.panels = document.querySelectorAll('[role="tabpanelsolid"]');
    this.tabs.forEach(function(value, index) {
      if (index != 0) {
        value.setAttribute("aria-selected", "false");
      }
    });
  },
  methods: {
    openTab: function(event) {
      this.deactivateAll();
      var outerText = "";
      if (event.target) {
        outerText = event.target.outerText;
      } else {
        outerText = event.outerText;
      }
      for (var k = 0; k < this.buttonNames.length; k++) {
        if (outerText === this.buttonNames[k].title) {
          var controls = this.buttonNames[k].getTab;
          for (var l = 0; l < this.tabs.length; l++) {
            if (outerText === this.tabs[l].outerText) {
              this.tabs[l].setAttribute("aria-selected", "true");
            }
          }
          // Remove hidden attribute from tab panel to make it visible
          document.getElementById(controls).removeAttribute("hidden");
        }
      }
    },
    deactivateAll: function() {
      for (var i = 0; i < this.panels.length; i++) {
        this.panels[i].setAttribute("hidden", "hidden");
      }
      for (var j = 0; j < this.tabs.length; j++) {
        this.tabs[j].setAttribute("tabindex", "-1");
        this.tabs[j].setAttribute("aria-selected", "false");
      }
    },
    keyupEventListener: function(event, index) {
      var pressed = event.keyCode;

      if (this.direction[pressed]) {
        if (index !== undefined) {
          if (this.tabs[index + this.direction[pressed]]) {
            this.tabs[index + this.direction[pressed]].focus();
            var goToTab = this.tabs[index + this.direction[pressed]];
            this.openTab(goToTab);
          } else if (pressed === this.keys.left) {
            this.focusLastTab();
          } else if (pressed === this.keys.right) {
            this.focusFirstTab();
          }
        }
      }
    },
    focusLastTab() {
      this.tabs[this.tabs.length - 1].focus();
      this.openTab(this.tabs[this.tabs.length - 1]);
    },
    focusFirstTab() {
      this.tabs[0].focus();
      this.openTab(this.tabs[0]);
    },
  },
};
</script>

<style scoped>
  /*///////////////////////////// 
    TABS - SOLID 
  /////////////////////////////*/
  
  div.tabs-solid > [role="tablist"] > [role="tabsolid"]{
    border-radius: 0;
    border: 0px solid transparent;
    border-top: 0;
    border-bottom: 0;
    margin: 0 5px 0 0;
    background: var(--gray-300);
    overflow: visible;
    z-index: 9;
  }
  div.tabs-solid > [role="tablist"] > [role="tabsolid"][aria-selected="true"]{
    border: 0px solid var(--gray-200);
    border-top: 0;
    border-bottom: 0;
    background: var(--gray-100);
    background: white;
  }
  div.tabs.tabs-solid > div.tabpanel-container{
    border-top: 0px solid var(--gray-400);
    margin-top: 0;
  }
  
  div.tabs-solid > div.tabpanel-container > div[role="tabpanelsolid"]{
    border: 0px solid var(--gray-300);
    min-height: 300px;
  }
  
  div.tabs-solid > [role="tablist"] > [role="tabsolid"]::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 2px;
    width: 100%;
    border-radius: 0;
    background: var(--green-800);
    opacity: 0;
    transition: all .2s ease-in-out;
  }
  
  
  
  /*/////////////////////////////////////// 
    RED - TAB COLOR SETTINGS 
  ///////////////////////////////////////*/
  
  div.tabs-solid > .tabs-red [role="tabsolid"]{
    border: 0!important;
  }
  div.tabs-solid > .tabs-red [role="tabsolid"]::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 2px;
    width: 100%;
    border-radius: 0;
    background: var(--red-800);
    opacity: 0;
    transition: all .2s ease-in-out;
  }
  
  div.tabs-solid > div.tabs-red [role="tabsolid"]:hover{
      background: var(--red-100)!important;
    }
  div.tabs-solid > div.tabs-red [role="tabsolid"][aria-selected="true"]{
      background: white!important;
    }
    
  div.tabs-solid > div.tabs-red [role="tabsolid"]:focus::after{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 2px;
        width: 100%;
        border-radius: 0;
        background: var(--red-800);
        opacity: 1;
        transition: all .2s ease-in-out;
    }
  
  /*/////////////////////////////////////// 
    BLUE - TAB COLOR SETTINGS
  ///////////////////////////////////////*/
  
  div.tabs-solid > .tabs-blue [role="tabsolid"]{
    border: 0!important;
  }
  div.tabs-solid > .tabs-blue [role="tabsolid"]::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 2px;
    width: 100%;
    border-radius: 0;
    background: var(--blue-800);
    opacity: 0;
    transition: all .2s ease-in-out;
  }
  
  div.tabs-solid > div.tabs-blue [role="tabsolid"]:hover{
      background: var(--blue-200)!important;
    }
  div.tabs-solid > div.tabs-blue [role="tabsolid"][aria-selected="true"]{
      background: white!important;
    }
  
  div.tabs-solid > div.tabs-blue [role="tabsolid"]:focus::after{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 2px;
      width: 100%;
      border-radius: 0;
      background: var(--blue-800);
      opacity: 1;
      transition: all .2s ease-in-out;
  }
  
  /*/////////////////////////////////////// 
    GREEN - TAB COLOR SETTINGS
  ///////////////////////////////////////*/
  
  div.tabs-solid > .tabs-green [role="tabsolid"]{
    border: 0!important;
  }
  div.tabs-solid > .tabs-green [role="tabsolid"]::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 2px;
    width: 100%;
    border-radius: 0;
    background: var(--green-800);
    opacity: 0;
    transition: all .2s ease-in-out;
  }
  
  div.tabs-solid > div.tabs-green [role="tabsolid"]:hover{
      background: var(--green-200)!important;
    }
  div.tabs-solid > div.tabs-green [role="tabsolid"][aria-selected="true"]{
      background: white!important;
    }
  
  div.tabs-solid >  div.tabs-green > [role="tabsolid"]:focus::after{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 2px;
      width: 100%;
      border-radius: 0;
      background: var(--green-800);
      opacity: 1;
      transition: all .2s ease-in-out;
  }
</style>