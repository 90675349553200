<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TempLeftNav />
    <div class="page">
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Tabs</h1>
              <div class="pagehead-link">
                <a href=""
                  ><img src="/html-components/images/svg/link.svg" alt=""
                /></a>
              </div>
            </div>
            <p>
              Tabs let users navigate between sections of related information,
              displaying only one section at a time.
            </p>
          </div>
        </section>
        <div class="divider max-w1200"></div>
        <div class="flex-sidebar flex-wrap">
          <div class="container max-w1000">
            <section id="general-guidelines">
              <div class="content-band">
                <h2>General Guidelines</h2>
                <div class="pt-20">
                  <h5 class="semibold">When to Consider Tabs</h5>
                  <ul class="bullet-red">
                    <li>
                      If content can be usefully and logically separated into
                      clearly labeled sections.
                    </li>
                    <li>
                      If the user does not need to view more than one section at
                      a time.
                    </li>
                    <li>
                      If it benefits the user to be able to focus on one section
                      at a time.
                    </li>
                    <li>
                      If it benefits the user to be able to quickly switch
                      between sections.
                    </li>
                  </ul>
                </div>
                <div class="pt-20">
                  <h5 class="semibold">When not to use Tabs</h5>
                  <ul class="bullet-red">
                    <li>
                      If tabs wrap over more than one line
                    </li>
                    <li>
                      If content needs to be read in order.
                    </li>
                    <li>
                      If users need to see information from multiple tabs at the
                      same time.
                    </li>
                    <li>
                      If users need to compare information in different tabs.
                    </li>
                  </ul>
                </div>
                <div class="pt-20">
                  <h5 class="semibold">Caution!</h5>
                  <ul class="bullet-red">
                    <li>
                      Careful consideration is required before using this
                      component, since Tabs hide content.
                    </li>
                    <ul class="bullet-red">
                      <li>
                        Not all users will notice Tabs or know how to use Tabs.
                        Some users may not see all content.
                      </li>
                      <li>
                        Tabs increase cognitive load and interaction cost
                        because users must make decisions as to what to click
                        on.
                      </li>
                    </ul>
                    <li>
                      It is often easier for the user to scroll down a page then
                      to decide what topics to click. Users will scroll when
                      information is valuable and properly formatted.
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <div class="divider"></div>
            <section id="usability">
              <div class="content-band">
                <h2>Usability</h2>
                <ul class="bullet-red">
                  <li>
                    If there are too many tabs or labels are too long, it makes
                    it harder for the user to see the connection between the
                    selected tab and it's content, as well as remember what tabs
                    they've already visited.
                  </li>
                </ul>
              </div>
            </section>

            <div class="divider"></div>
            <section id="import-component">
              <div class="content-band">
                <h2>Import the Component</h2>
                <ImportLinkComponent id="tab-css" />
                <ImportLinkComponent id="tab-js" />
              </div>
            </section>
            <div class="divider"></div>

            <section id="default-tab">
              <div class="content-band">
                <h3>Default Tab</h3>
                <CodeView component="tabs" type="default-tabs">
                  <TabComponentDefault />
                </CodeView>
              </div>
            </section>
            <section id="solid-tab">
              <div class="content-band">
                <h3>Solid Tab</h3>
                <CodeView component="tabs" type="solid-tabs">
                  <TabComponentSolid />
                </CodeView>
              </div>
            </section>
          </div>
          <StickyTOC />
        </div>
      </div>
      <SubFooter />
    </div>
  </div>
</template>

<script>
import TabComponentDefault from "../Tabs/TabComponentDefault";
import TabComponentSolid from "../Tabs/TabComponentSolid";
import CodeView from "../../CodeView";
import TempLeftNav from "../../TempLeftNav";
import SubFooter from "../../../components/SubFooter";
import ImportLinkComponent from "../../../components/ImportLinkComponent";
import StickyTOC from "../../StickyTOC";
export default {
  name: "Tab",
  data: function() {
    return {};
  },
  components: {
    CodeView,
    TabComponentDefault,
    TabComponentSolid,
    TempLeftNav,
    SubFooter,
    ImportLinkComponent,
    StickyTOC,
  },
};
</script>
